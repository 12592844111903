import type { Dict, Either } from '@obvio/utils'

export type Link = { href: string; title: Dict }

export type LinkGroup = {
  title: Dict
} & Either<{ href: string }, { links: Link[] }>

export const links: LinkGroup[] = [
  {
    title: {
      pl: 'Zatrzymaj się',
      en: 'Stay',
    },
    links: [
      {
        title: {
          pl: 'Pokoje & Apartamenty',
          en: 'Rooms & Apartments',
        },
        href: '/pokoje',
      },
      {
        title: {
          pl: 'Pakiety pobytowe',
          en: 'Packages',
        },
        href: '/pakiety',
      },
      {
        title: {
          pl: 'O nas',
          en: 'About us',
        },
        href: '/o-nas',
      },
      {
        title: {
          pl: 'Atrakcje Jury',
          en: 'Jura attractions',
        },
        href: '/jura',
      },
    ],
  },
  {
    title: {
      pl: 'Zregeneruj się',
      en: 'Regenerate',
    },
    links: [
      {
        title: {
          pl: '511 Organic Medi SPA',
          en: '511 Organic Medi SPA',
        },
        href: '/spa',
      },
      {
        title: {
          pl: 'Basen',
          en: 'Swimming pool',
        },
        href: '/basen',
      },

      {
        title: {
          pl: 'Sauny',
          en: 'Saunas',
        },
        href: '/sauny',
      },
      {
        title: {
          pl: 'Atrakcje sportowe',
          en: 'Sport attractions',
        },
        href: '/sport',
      },
    ],
  },
  {
    title: {
      pl: 'Smakuj',
      en: 'Taste',
    },
    links: [
      {
        title: {
          pl: 'Restauracja & Bar 511',
          en: 'Restaurant & Bar 511',
        },
        href: '/restauracja',
      },
      {
        title: {
          pl: 'Winnica',
          en: 'Winery',
        },
        href: '/winnica',
      },
    ],
  },
  {
    title: {
      pl: 'Pracuj i świętuj',
      en: 'Work & Celebrate',
    },
    links: [
      {
        title: {
          pl: 'Konferencje i szkolenia',
          en: 'Conferences & trainings',
        },
        href: '/biznes',
      },
      {
        title: {
          pl: 'Przyjęcia weselne i inne',
          en: 'Weddings & other events',
        },
        href: '/przyjecia',
      },
    ],
  },
  {
    title: {
      pl: 'DZIEJE SIĘ W 511',
      en: 'EVENTS IN 511',
    },
    links: [
      {
        title: {
          pl: 'Jurajskie Spotkania Wspinaczkowe',
          en: 'Jura Climbing Meetings',
        },
        href: '/spotkaniawspinaczkowe',
      },
      {
        title: {
          pl: 'Kalendarz atrakcji',
          en: 'Calendar of attractions',
        },
        href: '/atrakcje',
      },
    ],
  },
  {
    title: {
      pl: 'WIZYTA BEZ NOCLEGU',
      en: 'VISIT WITHOUT LODGING',
    },
    links: [
      {
        title: {
          pl: 'Sport',
          en: 'Sport',
        },
        href: '/aura',
      },
      {
        title: {
          pl: 'Wino',
          en: 'Wine',
        },
        href: '/wino',
      },
      {
        title: {
          pl: 'Muzyka',
          en: 'Music',
        },
        href: '/muzyka',
      },
      {
        title: {
          pl: 'Spędź czas w Poziom 511',
          en: 'Spend time at Poziom 511',
        },
        href: '/wizyta-bez-noclegu',
      },
    ],
  },
  {
    href: '/sklep',
    title: {
      pl: 'Sklep',
      en: 'Shop',
    },
  },
  {
    href: '/blog',
    title: {
      pl: 'Blog',
      en: 'Blog',
    },
  },
  {
    href: '/kontakt',
    title: {
      pl: 'Skontaktuj się',
      en: 'Contact us',
    },
  },
]
