import { styled } from '@obvio/app'
import { Stack } from '@obvio/ui'

import { Title } from './styles'

import type { FixedProps } from './styles'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type InlineBarElementProps = AllowUndefinedForOptional<{
  title: string
  fixedBar?: boolean
  children: ReactElement
}>

const StyledStack = styled(Stack)<FixedProps>`
  align-items: ${(_, { $fixedBar }) => ($fixedBar ? 'center' : 'initial')};
  height: 100%;
`

export function InlineBarElement({
  title,
  fixedBar,
  children,
}: InlineBarElementProps): ReactElement {
  return (
    <StyledStack
      $fixedBar={fixedBar}
      kind={fixedBar ? 'horizontal' : 'vertical'}
    >
      <Title>{title}</Title>
      {children}
    </StyledStack>
  )
}
