import { mobile, styled } from '@obvio/app'
import { Section } from '@obvio/ui'
import { motion } from 'framer-motion'

import type { SectionProps } from '@obvio/ui'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

export const MaxWidthContent = styled.div`
  max-width: 46.5625rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`

type StyledCenteredSectionProps = AllowUndefinedForOptional<{
  $withMargin?: boolean | undefined
}>

const StyledCenteredSection = styled(Section)<StyledCenteredSectionProps>`
  ${(theme) => theme.flexCenter};
  margin-top: ${(theme, { $withMargin }) =>
    $withMargin ? `calc(${theme.spacing.large})` : 'initial'};
`

const MotionSection = motion(StyledCenteredSection)

type CenteredSectionProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[]
  className?: string
  delay?: number
  withMargin?: StyledCenteredSectionProps['$withMargin']
}> &
  SectionProps

export function CenteredSection({
  children,
  className,
  delay = 0.25,
  withMargin,
  ...sectionProps
}: CenteredSectionProps): ReactElement {
  return (
    <MotionSection
      {...sectionProps}
      $withMargin={withMargin}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay, duration: 1 }}
      className={className}
    >
      {children}
    </MotionSection>
  )
}

export const LeftSection = styled(Section)`
  justify-content: flex-start;
`

export const QuoteSection = styled(LeftSection)`
  h3 > b {
    font-weight: 400;
    font-family: ${(theme) => theme.font};
  }
  h3 {
    font-size: 3rem;
    font-family: ${(theme) => theme.secondaryFont};
    @media ${mobile} {
      font-size: 2rem !important;
    }
  }
`
