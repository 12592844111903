import {
  LANGUAGES,
  Link,
  mobile,
  styled,
  switchLang,
  useTranslation,
} from '@obvio/app'
import { SvgFacebook, SvgInstagram } from '@obvio/svg'
import { Button, Stack } from '@obvio/ui'
import { Fragment } from 'react'

import type { Dict } from '@obvio/utils'
import type { ReactElement } from 'react'

const Wrap = styled(Stack)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 2.25rem;
  align-items: center;
  color: ${(theme) => theme.colors.complementary};
  > a,
  button {
    transition: 200ms;
    :hover {
      color: black;
    }
  }
  @media ${mobile} {
    top: 1rem;
  }
`

const LangButton = styled(Button)`
  font-size: 1rem;
  min-width: unset;
  width: unset;
  height: unset;
  padding: 0.5rem;
  color: ${(theme) => theme.colors.complementary.semidark};
  font-weight: 400;

  & + & {
    margin-left: 1.5rem;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 1.5rem;
      position: absolute;
      left: -0.75rem;
      background-color: ${(theme) => theme.colors.complementary};
      pointer-events: none;
    }
  }

  &:last-of-type {
    margin-right: 0.75rem;
  }
`

const langsMap: Dict = {
  en: 'ENG',
}

function LangSwitcher(): ReactElement {
  const { locale: currentLang } = useTranslation()
  return (
    <>
      {LANGUAGES.map((lang) => (
        <Fragment key={lang}>
          <LangButton
            kind="transparent"
            onClick={() => {
              switchLang(lang)
            }}
            disabled={lang === currentLang}
          >
            {langsMap[lang] ?? lang.toUpperCase()}
          </LangButton>
        </Fragment>
      ))}
    </>
  )
}

export function NavLangsAndSocials(): ReactElement {
  return (
    <Wrap>
      <LangSwitcher />
      <Link href="https://www.facebook.com/poziom511/">
        <SvgFacebook />
      </Link>
      <Link href="https://www.instagram.com/poziom511/">
        <SvgInstagram />
      </Link>
    </Wrap>
  )
}
