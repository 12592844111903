import { mobile, styled } from '@obvio/app'
import { Grid, Button, GridItem, Stack, Submit } from '@obvio/ui'

export type FixedProps = { $fixedBar?: boolean | undefined }

export const Panel = styled.div<FixedProps>`
  width: 100%;
  ${(theme) => theme.flexCenter};
  background: ${(theme, { $fixedBar }) =>
    $fixedBar ? theme.colors.primary : 'transparent'};
  height: ${(_, { $fixedBar }) => ($fixedBar ? '3rem' : '7rem')};
  position: ${(_, { $fixedBar }) => ($fixedBar ? 'fixed' : 'relative')};
  border-top: ${(theme, { $fixedBar }) =>
    $fixedBar ? `1px solid ${theme.stroke}` : 'none'};
  inset: auto 0 0 0;
  z-index: 3;
  * {
    color: ${(theme, { $fixedBar }) =>
      $fixedBar ? theme.colors.secondary.alpha(0.65) : 'inherit'};
  }

  @media ${mobile} {
    position: ${(_, { $fixedBar }) => ($fixedBar ? 'fixed' : 'relative')};
    inset: unset;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4rem;
  }
`

export const MobileButton = styled(Button)`
  display: none;
  height: 100%;
  width: 100%;
  border-top: 1px solid ${(theme) => theme.stroke};
  > div {
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  @media ${mobile} {
    display: initial;
  }
`

export const ContentWrap = styled(Grid)`
  border-bottom: 1px solid ${(theme) => theme.stroke};
  max-width: 79rem;
  margin: 0 2rem;
  width: 100%;
  height: 100%;
  @media ${mobile} {
    display: none;
  }
`

export const Title = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 1px;
`
export const Value = styled.span<FixedProps>`
  font-size: ${(_, { $fixedBar }) => ($fixedBar ? '1.5rem' : '2.25rem')};
  font-weight: 300;
`

export const CheckButton = styled(Button)`
  color: inherit;
  font-size: 1.125rem;
  > span {
    margin-right: ${(theme) => theme.spacing.medium};
  }
`

export const CheckSubmit = styled(Submit)`
  color: inherit;
  font-size: 1.125rem;
  > span {
    margin-right: ${(theme) => theme.spacing.medium};
  }
`

export const Cell = styled(GridItem)`
  @media ${mobile} {
    display: none;
  }
`

export const CheckCell = styled(Cell)`
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: end;
`

export const AlignedStack = styled(Stack)`
  align-items: center;
  cursor: pointer;
`
export const TimeInputStyle = styled.input`
  font-size: 2.25rem;
  background: transparent;
  border: none;
`

export const GuestsInput = styled.input`
  width: 2rem;
  background: transparent;
  border: none;
`
