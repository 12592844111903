import { Link, mobile, styled } from '@obvio/app'
import { SvgHalfLogo } from '@obvio/svg'
import { useUi } from '@obvio/template'
import { Stack } from '@obvio/ui'
import { AnimatePresence, useAnimation } from 'framer-motion'
import { useCallback, useEffect } from 'react'

import { useLayoutType } from '../Layout'
import { NavBar } from './components'
import { NavAbstract } from './NavAbstract'
import { NavButton } from './NavButton'
import { NavMenu } from './NavMenu'
import { heroSectionStore } from '@/utils/heroSectionStore'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const BgBlock = styled.div`
  @media ${mobile} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background-color: ${(theme) => theme.background};
    z-index: 10;
    border-bottom: 1px solid ${(theme) => theme.stroke};
  }
`

type NavProps = AllowUndefinedForOptional<{
  black?: boolean
  noAbstract?: boolean
}>

export function Nav({ black, noAbstract }: NavProps): ReactElement {
  const controls = useAnimation()
  const layoutType = useLayoutType()
  const [{ menuPanelOpen }, dispatch] = useUi('menuPanelOpen')
  const [{ hero }] = heroSectionStore('hero')
  const togglePanel = () =>
    dispatch(menuPanelOpen ? 'CLOSE_MENU_PANEL' : 'OPEN_MENU_PANEL')

  const setAbsoluteTop = useCallback(
    () => window.scrollY === 0 && controls.set({ position: 'absolute' }),
    [controls],
  )

  useEffect(() => {
    document.addEventListener('scroll', setAbsoluteTop)
    return () => document.removeEventListener('scroll', setAbsoluteTop)
  }, [setAbsoluteTop])

  return (
    <>
      <NavBar
        $black={Boolean(black)}
        $opened={menuPanelOpen}
        $layoutType={layoutType}
      >
        <Stack>
          <NavButton onClick={togglePanel} open={menuPanelOpen} />
          <Link
            href="/"
            anchorProps={{ 'aria-label': 'Strona główna' }}
            onClick={() => dispatch('CLOSE_ALL_PANELS')}
          >
            <SvgHalfLogo />
          </Link>
        </Stack>
      </NavBar>
      {!hero && <BgBlock />}
      <AnimatePresence>
        <NavMenu />
      </AnimatePresence>
      {!noAbstract && <NavAbstract />}
    </>
  )
}
