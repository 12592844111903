import { SvgMinus, SvgPlus } from '@obvio/svg'

import { AlignedStack, Value, GuestsInput } from './styles'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type NumberFieldProps = AllowUndefinedForOptional<{
  value: number
  fixedBar?: boolean
  update(value: number): void
}>

export function NumberField({
  value,
  update,
  fixedBar,
}: NumberFieldProps): ReactElement {
  const addOne = () => value < 9 && update(value + 1)
  const removeOne = () => value > 1 && update(value - 1)

  return (
    <AlignedStack>
      <SvgMinus onClick={removeOne} />
      <Value $fixedBar={fixedBar}>
        <GuestsInput
          aria-label="guests"
          value={value}
          onChange={(ev) => update(Number(ev.target.value))}
          max={9}
          readOnly
        />
      </Value>
      <SvgPlus onClick={addOne} />
    </AlignedStack>
  )
}
