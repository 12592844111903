import { styled, useTranslation } from '@obvio/app'
import { SvgArrowSimple } from '@obvio/svg'
import { FadeIn } from '@obvio/template'
import { Form, Stack } from '@obvio/ui'
import { sortableDateFormat } from '@obvio/utils'
import { motion } from 'framer-motion'
import { forwardRef, useReducer } from 'react'

import { DateField } from './DateField'
import { InlineBarElement } from './InlineBarElement'
import { NumberField } from './NumberField'
import {
  Cell,
  CheckButton,
  CheckCell,
  ContentWrap,
  MobileButton,
  Panel,
} from './styles'
import { useProfitRoom } from '../useProfitRoom'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const AnimationCta = motion(CheckButton)
const MotionArrowSimple = SvgArrowSimple

// Helpers for initial values
const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const initialState = {
  dateFrom: today,
  dateTo: tomorrow,
  guests: 2,
}

type State = typeof initialState

type Action =
  | { type: 'FROM'; payload: Date }
  | { type: 'TO'; payload: Date }
  | { type: 'GUESTS'; payload: number }

const reducer = (state: typeof initialState, action: Action): State => {
  switch (action.type) {
    case 'FROM': {
      if (state.dateTo < action.payload) {
        const newDateCopy = new Date(action.payload)
        const dateTo = new Date(newDateCopy.setDate(newDateCopy.getDate() + 1))
        return { ...state, dateTo, dateFrom: action.payload }
      }
      return { ...state, dateFrom: action.payload }
    }
    case 'TO': {
      return { ...state, dateTo: action.payload }
    }
    case 'GUESTS': {
      return { ...state, guests: action.payload }
    }
    default: {
      throw new Error('Incorrect action.type')
    }
  }
}

type InlineBookCalendarProps = AllowUndefinedForOptional<{
  fixedBar?: boolean
  footer?: boolean
  className?: string
}>

const ArrowWrap = styled.div`
  display: flex;
`

const MotionArrowWrap = motion(ArrowWrap)

type WrapProps = {
  fixedBar: boolean | undefined
  footer: boolean | undefined
  children: ReactElement
}

function Wrap({ fixedBar, footer, children }: WrapProps) {
  if (fixedBar) {
    return children
  }
  return <FadeIn delay={footer ? undefined : 1.5}>{children}</FadeIn>
}

export const InlineBookCalendar = forwardRef<
  HTMLDivElement,
  InlineBookCalendarProps
>(({ fixedBar, footer, className }, ref) => {
  const { bookingOpen } = useProfitRoom()
  const { t } = useTranslation()

  const [{ dateFrom, dateTo, guests }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  return (
    <Wrap fixedBar={fixedBar} footer={footer}>
      <Form contextOnly>
        <Panel ref={ref} $fixedBar={fixedBar}>
          <ContentWrap
            className={className}
            templateColumns="repeat(4,minmax(0,1fr))"
          >
            <Cell noCenter>
              <InlineBarElement title={t('label.arrival')} fixedBar={fixedBar}>
                <DateField
                  fixedBar={fixedBar}
                  value={dateFrom}
                  update={(payload) => dispatch({ type: 'FROM', payload })}
                />
              </InlineBarElement>
            </Cell>
            <Cell noCenter>
              <InlineBarElement
                title={t('label.departure')}
                fixedBar={fixedBar}
              >
                <DateField
                  fixedBar={fixedBar}
                  value={dateTo}
                  update={(payload) => dispatch({ type: 'TO', payload })}
                  minDate={dateFrom}
                />
              </InlineBarElement>
            </Cell>
            <Cell noCenter>
              <InlineBarElement title={t('label.adults')} fixedBar={fixedBar}>
                <NumberField
                  fixedBar={fixedBar}
                  value={guests}
                  update={(payload) => dispatch({ type: 'GUESTS', payload })}
                />
              </InlineBarElement>
            </Cell>
            <CheckCell noCenter>
              <AnimationCta
                whileHover="hover"
                type="submit"
                kind="transparent"
                onClick={() =>
                  bookingOpen?.({
                    checkin: sortableDateFormat(dateFrom).slice(0, 10),
                    checkout: sortableDateFormat(dateTo).slice(0, 10),
                    adults: String(guests),
                  })
                }
              >
                <span>{t('label.checkAvailability')}</span>
                <MotionArrowWrap variants={{ hover: { x: 5 } }}>
                  <MotionArrowSimple />
                </MotionArrowWrap>
              </AnimationCta>
            </CheckCell>
          </ContentWrap>
          <MobileButton
            onClick={() => bookingOpen?.({})}
            kind={['transparent']}
          >
            <Stack>
              <span>{t('label.pickDates')}</span>
              <SvgArrowSimple />
            </Stack>
          </MobileButton>
        </Panel>
      </Form>
    </Wrap>
  )
})
