import { styled, useTranslation } from '@obvio/app'
import { SvgChevronDown } from '@obvio/svg'
import { Dropdown } from '@obvio/ui'

import { AlignedStack, Value } from './styles'
import { Calendar } from '../Calendar'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type DateFieldProps = AllowUndefinedForOptional<{
  value: Date
  minDate?: Date
  fixedBar?: boolean
  update(value: Date): void
}>

const StyledDropdown = styled(Dropdown)`
  background: transparent;
  border-radius: 0;
`

export function DateField({
  value,
  update,
  minDate,
  fixedBar,
}: DateFieldProps): ReactElement {
  const { locale } = useTranslation()
  const day = value.getDate()
  const month = value.toLocaleDateString(locale, { month: 'short' })

  return (
    <StyledDropdown
      render={({ close }) => (
        <Calendar
          minDate={minDate ?? new Date()}
          value={value || undefined}
          onChange={(e: Date) => {
            update(e)
            close()
          }}
        />
      )}
    >
      <div>
        <AlignedStack>
          <Value $fixedBar={fixedBar}>{`${day} ${month}`}</Value>
          <SvgChevronDown />
        </AlignedStack>
      </div>
    </StyledDropdown>
  )
}
