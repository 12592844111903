import { styled } from '@obvio/app'
import { SvgCloseMenu, SvgSmallMenu } from '@obvio/svg'
import { Button } from '@obvio/ui'

import type { ButtonProps } from '@obvio/ui'
import type { ReactElement } from 'react'

type NavButtonProps = ButtonProps & {
  open: boolean
}

const StyledNavButton = styled(Button)`
  color: inherit;
`

export function NavButton({
  open,
  ...buttonProps
}: NavButtonProps): ReactElement {
  return (
    <StyledNavButton
      {...buttonProps}
      aria-label="menu"
      kind={['icon', 'transparent']}
    >
      {open ? <SvgCloseMenu /> : <SvgSmallMenu />}
    </StyledNavButton>
  )
}
