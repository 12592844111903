import { css, styled, useIsMobile, Link, useTranslation } from '@obvio/app'
import { SvgArrowSimple, SvgCalendar } from '@obvio/svg'
import { useUi } from '@obvio/template'
import { Accordion, Stack } from '@obvio/ui'
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  AccordionNoStyles,
  CheckAll,
  EventsPanel,
  GroupElement,
  GroupTitle,
  Menu,
  MotionMenu,
  StackList,
  StyledDrawer,
} from './components'
import { links } from './consts'
import { ExpandMorePanel } from './NavExpandMorePanel'
import { NavLangsAndSocials } from './NavLangsAndSocials'
import { Events } from '../Renderer/RendererVariants/Events/Events'

import type { LinkGroup } from './consts'
import type { ReactElement } from 'react'

const Backdrop = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1;
`

const GroupStack = styled(Stack)`
  margin-top: ${(theme) => theme.spacing.small};
  padding-bottom: 0.1rem;
`

const MotionDrawer = motion(StyledDrawer, { forwardMotionProps: true })
const MotionBackdrop = motion(Backdrop)
const MotionGroupTitle = motion(GroupTitle)
const MotionGroupElement = motion(GroupElement)

type GroupProps = {
  group: LinkGroup
}

function LineAnimation() {
  return (
    <motion.div
      transition={{
        type: 'tween',
        duration: 0.25,
      }}
      initial={{
        position: 'absolute',
        left: '0%',
        height: '1px',
        background: '#31352C',
      }}
      variants={{
        hovering: {
          width: '100%',
        },
      }}
    />
  )
}

function Group({ group }: GroupProps) {
  const { locale } = useTranslation()

  return (
    <GroupStack list kind="vertical" spacing="medium">
      {group.links?.map((link) => (
        <MotionGroupElement whileHover="hovering" key={link.href}>
          <Link href={link.href}>{link.title[locale]}</Link>
          <LineAnimation />
        </MotionGroupElement>
      ))}
    </GroupStack>
  )
}

const contentStyles = css`
  background: transparent;
  z-index: 50;
`

const Arrow = styled(SvgArrowSimple)`
  transform: rotate(90deg);
  margin-left: ${(theme) => theme.spacing.small};
  height: 1.25rem !important;
  width: 1.25rem !important;
`

const ExpandMorePanelBlack = styled(ExpandMorePanel)`
  color: ${(theme) => theme.colors.secondary};
`

export function NavMenu(): ReactElement | null {
  const { locale } = useTranslation()

  const initial = useRef(true)
  const animate = useAnimationControls()
  const [eventsOpened, setEventsOpened] = useState(false)
  const isMobile = useIsMobile()
  const [{ menuPanelOpen }, dispatch] = useUi('menuPanelOpen')
  //  const scrollLockRef = useScrollLock(Boolean(menuPanelOpen))

  const toggleEventsPanel = useCallback(
    () => setEventsOpened((current) => !current),
    [],
  )
  const closePanels = useCallback(
    () => dispatch('CLOSE_ALL_PANELS'),
    [dispatch],
  )

  const panelDirection = isMobile ? 'y' : 'x'

  useEffect(() => {
    if (initial.current) {
      void animate.start('drawerInitial')
      initial.current = false
    } else if (menuPanelOpen) {
      void animate.start('drawerAnimate')
    } else {
      // eslint-disable-next-line promise/prefer-await-to-then
      void animate.start('drawerExit').then(() => {
        void animate.start('drawerExited')
      })
    }
    initial.current = false
  }, [animate, menuPanelOpen, panelDirection])

  const customBackdrop = useCallback(
    () => (
      <MotionBackdrop
        onClick={closePanels}
        variants={{
          drawerAnimate: {
            background: 'rgba(0,0,0,0.55)',
          },
          drawerExit: {
            background: 'rgba(0,0,0,0)',
          },
        }}
        transition={{
          duration: 0.75,
        }}
      />
    ),
    [closePanels],
  )

  return (
    <MotionDrawer
      contentStyle={contentStyles}
      noMaxWidth
      visible={Boolean(menuPanelOpen)}
      handleClickOutside={closePanels}
      position="left"
      animate={animate}
      // TODO: FIXME, it's not working at all tho a simple case on codesandbox works
      customBackdrop={customBackdrop}
      variants={{
        drawerInitial: {
          zIndex: -1,
          position: 'fixed',
        },
        drawerAnimate: {
          zIndex: 50,
        },
        drawerExited: {
          zIndex: -1,
        },
      }}
    >
      <MotionMenu
        initial={{
          [panelDirection]: panelDirection === 'x' ? '-44rem' : '-100%',
        }}
        variants={{
          drawerInitial: {
            [panelDirection]: panelDirection === 'x' ? '-44rem' : '-100%',
          },
          drawerAnimate: {
            [panelDirection]: panelDirection === 'x' ? '0rem' : '0%',
            [panelDirection === 'x' ? 'y' : 'x']: 0,
            opacity: '1',
          },
          drawerExit: {
            [panelDirection]: panelDirection === 'x' ? '-44rem' : '-100%',
            [panelDirection === 'x' ? 'y' : 'x']: 0,
          },
          drawerExited: {
            opacity: '0',
          },
        }}
        transition={{
          type: 'tween',
          duration: 0.75,
        }}
        key="menu"
      >
        <Menu>
          <NavLangsAndSocials />
          <StackList list kind="vertical" spacing="2.65rem">
            <Accordion>
              {links.map((group) => {
                if (!group.links && 'href' in group) {
                  return (
                    <MotionGroupTitle
                      key={group.title[locale]}
                      whileHover="hovering"
                      onClick={() => dispatch('CLOSE_ALL_PANELS')}
                    >
                      <Link href={group.href}>{group.title[locale]}</Link>
                      <LineAnimation />
                    </MotionGroupTitle>
                  )
                }
                return (
                  <AccordionNoStyles
                    key={group.title[locale]}
                    chevronPosition="none"
                    title={(open) => (
                      <MotionGroupTitle
                        $open={open}
                        whileHover={{ x: open ? 0 : 5 }}
                      >
                        {group.title[locale]}
                        {open && <Arrow />}
                      </MotionGroupTitle>
                    )}
                  >
                    <Group group={group} />
                  </AccordionNoStyles>
                )
              })}
            </Accordion>
          </StackList>
        </Menu>
        <ExpandMorePanelBlack
          href="/atrakcje"
          title={
            <Link href="/atrakcje">
              <SvgCalendar />
            </Link>
          }
          text="DZIEJE SIĘ W 511"
          onClick={toggleEventsPanel}
          opened={eventsOpened}
          rotated={false}
        />
        <AnimatePresence>
          {eventsOpened && (
            <motion.div
              initial={{ [panelDirection]: '-100%', width: '100vw' }}
              animate={{
                [panelDirection]: 0,
                transitionEnd: {
                  [panelDirection]: 0,
                },
              }}
              exit={{ [panelDirection]: '-100%' }}
              transition={{
                type: 'tween',
                duration: 1,
              }}
              variants={{
                drawerExit: {
                  [panelDirection]: '-125%',
                  transition: {
                    duration: 0.75,
                  },
                },
              }}
            >
              <EventsPanel templateRows="2rem 1fr">
                <CheckAll href="/atrakcje">
                  Sprawdź wszystkie wydarzenia
                </CheckAll>
                <Events kind="header" />
              </EventsPanel>
            </motion.div>
          )}
        </AnimatePresence>
      </MotionMenu>
    </MotionDrawer>
  )
}
