import { Link, mobile, styled, useRouter } from '@obvio/app'
import { SvgAbstract } from '@obvio/svg'
import { motion } from 'framer-motion'

import { heroSectionStore } from '@/utils/heroSectionStore'

import type { ReactElement } from 'react'

const Text = motion(styled.span`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`)
const MotionLink = motion(
  styled(Link)`
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 10;
    color: var(--nav-color, '#ffffff');
    @media ${mobile} {
      top: 1rem;
      right: 1rem;
    }
  `,
)

const Abstract = styled(SvgAbstract)`
  height: 2.55rem !important;
  @media ${mobile} {
    height: 1.85rem !important;
  }
`

export function NavAbstract(): ReactElement | null {
  const { asPath } = useRouter()
  const [{ hero }] = heroSectionStore('hero')

  const isLanding = asPath === '/'

  if (isLanding && hero) {
    return null
  }

  return (
    <MotionLink initial="hidden" whileHover="hover" href="/atrakcje">
      <Abstract />
      <Text
        variants={{
          hidden: { opacity: 0, y: '165%' },
          hover: { opacity: 1, y: '130%' },
        }}
        transition={{ delay: 0.15, duration: 0.75 }}
      >
        Dzieje się w 511
      </Text>
    </MotionLink>
  )
}
