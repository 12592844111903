import { Link, styled, tinyMobile } from '@obvio/app'
import { motion } from 'framer-motion'

import { Arrow } from './Arrow'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const StyledLinkWrapper = styled(Link)<{ $center?: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: ${(_, { $center }) => ($center ? 'center' : 'flex-start')};
  @media ${tinyMobile} {
    padding-top: ${(theme) => theme.spacing.small};
  }
  > div {
    display: flex;
    align-items: center;
    > div {
      margin-left: ${(theme) => theme.spacing.medium};
    }
  }

  font-weight: 500;
  letter-spacing: 3px;
`

const MotionLink = motion(StyledLinkWrapper)

type ReadMoreLinkProps = AllowUndefinedForOptional<{
  children?: React.ReactNode
  href?: string
  className?: string
  noArrow?: boolean
  center?: boolean
  onClick?(): void
}>

export function ReadMoreLink({
  children,
  href,
  className,
  onClick,
  noArrow,
  center,
}: ReadMoreLinkProps): ReactElement | null {
  return (
    <MotionLink
      onClick={onClick}
      className={className}
      href={href}
      whileHover="hover"
      $center={center}
    >
      <span>{children}</span>
      {!noArrow ? (
        <motion.div variants={{ hover: { x: [0, 10, 0] } }}>
          <Arrow direction="right" length="7rem" />
        </motion.div>
      ) : null}
    </MotionLink>
  )
}
