import { css, styled } from '@obvio/app'
import { Calendar as UiCalendar } from '@obvio/ui'

import { Arrow } from './Arrow'

import type { CalendarProps } from '@obvio/ui'
import type { ReactElement } from 'react'

export const calendarStyle = css`
  background: ${(theme) => theme.colors.primary};

  padding: ${(theme) => theme.spacing.small};

  width: 301px;

  font-family: ${(theme) => theme.font};
  * {
    font-weight: 300;
  }
  .react-calendar__navigation__label {
    color: ${(theme) => theme.colors.additional[0]};
    text-align: left;
  }

  .react-calendar__navigation__label {
    order: 1;
  }

  .react-calendar__navigation__prev-button {
    order: 2;
  }

  .react-calendar__navigation__next-button {
    order: 3;
  }

  .react-calendar__tile {
    position: relative;
    height: 43px !important;
    width: 43px !important;
    padding: 0;
    border-radius: 9999px;

    > abbr {
      font-size: 14px;
    }

    &:enabled:hover {
      background: unset;
      &:after:not(.react-calendar__tile--range) {
        content: '';
        position: absolute;
        border-radius: 9999px;
        inset: 6px;
        background: ${(theme) => theme.colors.additional[0]};
      }
    }
  }

  .react-calendar__tile--active {
    color: black;
    background: unset;
    &:enabled:focus {
      background: unset;
    }
    &:before {
      content: '';
      position: absolute;
      border-radius: 9999px;
      inset: 6px;
      background: ${(theme) => theme.colors.additional[0]};
    }
  }

  .react-calendar__tile--range {
    border-radius: 0px !important;
    &:before {
      content: '';
      position: absolute;
      border-radius: 0px;
      inset: 6px 0px;
      background: ${(theme) => theme.colors.additional[0]};
    }
  }

  .react-calendar__tile--rangeBothEnds {
    border-radius: 9999px;
    &:before {
      content: '';
      position: absolute;
      border-radius: 9999px;
      inset: 6px;
      background: ${(theme) => theme.colors.additional[0]};
    }
  }

  .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeEnd) {
    &:before {
      content: '';
      position: absolute;
      border-radius: 0px;
      border-top-left-radius: 9999px;
      border-bottom-left-radius: 9999px;
      inset: 6px;
      background: ${(theme) => theme.colors.additional[0]};
    }

    &:after {
      content: '';
      position: absolute;
      inset: 6px 0px 6px auto;
      width: 1rem;
      background: ${(theme) => theme.colors.additional[0]};
    }
  }

  .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeStart) {
    &:before {
      content: '';
      position: absolute;
      border-radius: 0px;
      border-top-right-radius: 9999px;
      border-bottom-right-radius: 9999px;
      inset: 6px;
      background: ${(theme) => theme.colors.additional[0]};
    }

    &:after {
      content: '';
      position: absolute;
      inset: 6px auto 6px 0px;
      width: 1rem;
      background: ${(theme) => theme.colors.additional[0]};
    }
  }

  .react-calendar__tile:disabled,
  .react-calendar__tile--now {
    background-color: unset;
  }

  .react-calendar__month-view__weekdays {
    color: rgba(0, 0, 0, 0.3);
  }

  .react-calendar__month-view__weekdays__weekday {
    font-weight: 300;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0;
  }

  abbr {
    text-decoration: none;
    position: relative;
    z-index: 1;
  }
`

const StyledCalendar = styled(UiCalendar)`
  ${calendarStyle}
`

const NavigationArrow = styled(Arrow)`
  > div {
    background: ${(theme) => theme.colors.additional[0]};
  }
  > svg {
    color: ${(theme) => theme.colors.additional[0]};
  }
`

export const poziomCalendarProps: CalendarProps = {
  prevLabel: <NavigationArrow direction="left" length="3rem" />,
  nextLabel: <NavigationArrow direction="right" length="3rem" />,
  next2Label: null,
  prev2Label: null,
  navigationLabel: ({ date, locale }) => {
    const format = new Intl.DateTimeFormat(locale, { month: 'long' })
    return format.format(date)
  },
  formatShortWeekday: (locale, date) => {
    const format = new Intl.DateTimeFormat(locale, { weekday: 'short' })
    return format.format(date)[0]
  },
  view: 'month',
  // showNeighboringMonth: false,
  minDate: new Date(),
}

export function Calendar(props: CalendarProps): ReactElement {
  return <StyledCalendar {...poziomCalendarProps} {...props} />
}
