import { styled, css } from '@obvio/app'
import { SvgChevronUp } from '@obvio/svg'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const StyledLine = styled.div`
  height: 1px;
  background: ${(theme) => theme.colors.secondary};
  width: 100%;
  margin: 0 0.4375rem;
`

const leftArrowStyle = css`
  transform: rotate(-180deg);
`

const downArrowStyle = css`
  transform: rotate(90deg);
`

const upArrowStyle = css`
  transform: rotate(0deg);
`

const StyledArrowWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 0.9375rem;
  color: ${(theme) => theme.colors.secondary};
  width: fit-content;
`

const StyledSvg = styled(SvgChevronUp)`
  transform: rotate(90deg);
  position: absolute;
  right: 1px;
  width: 0.9375rem !important;
  height: 0.9375rem !important;
`

type ArrowIconProps = AllowUndefinedForOptional<{
  length: string
  className?: string
}>

function ArrowIcon({ length, className }: ArrowIconProps) {
  return (
    <StyledArrowWrapper className={className}>
      <StyledLine
        className="arrow"
        css={css`
          width: ${length};
        `}
      />
      <StyledSvg />
    </StyledArrowWrapper>
  )
}

type ArrowProps = AllowUndefinedForOptional<{
  direction: 'right' | 'left' | 'up' | 'down'
  length: string
  className?: string
}>

export function Arrow({
  direction,
  length,
  className,
}: ArrowProps): ReactElement {
  return (
    <ArrowIcon
      className={className}
      length={length}
      css={css`
        ${(direction === 'left' && leftArrowStyle) ||
        (direction === 'up' && upArrowStyle) ||
        (direction === 'down' && downArrowStyle)}
      `}
    />
  )
}
