import { styled, useIsMobile } from '@obvio/app'
import { Button, Stack } from '@obvio/ui'
import { motion } from 'framer-motion'

import { MotionExpandMore, ExpandMoreArrow, RotatedText } from './components'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type ExpandMorePanelProps = AllowUndefinedForOptional<{
  opened: boolean
  title: string | ReactElement
  rotated?: boolean
  className?: string
  href?: string
  text?: string
  onClick(): void
}>

const OpenButton = styled(Button)`
  display: flex;
  flex-direction: column;
  height: auto;
`

const arrowVariants = {
  initial: {
    x: 0,
  },
  hover: {
    x: 5,
  },
  hoverClose: {
    x: -5,
  },
}

export function ExpandMorePanel({
  href,
  opened,
  onClick,
  title,
  text = 'WIĘCEJ',
  rotated = true,
  className,
}: ExpandMorePanelProps): ReactElement {
  const isMobile = useIsMobile()
  const panelDirection = isMobile ? 'y' : 'x'

  return (
    <MotionExpandMore
      className={className}
      initial={{ [panelDirection]: '-6.875rem' }}
      animate={{
        [panelDirection]: '0rem',
      }}
      whileHover={opened ? 'hoverClose' : 'hover'}
      exit={{ [panelDirection]: '-6.875rem' }}
      transition={{
        duration: 0.75,
      }}
    >
      <OpenButton kind={['icon', 'transparent']} onClick={onClick}>
        <div>
          <Stack kind={isMobile ? 'horizontal' : 'vertical'}>
            <motion.div variants={arrowVariants}>
              <ExpandMoreArrow $opened={opened || undefined} />
            </motion.div>
            {text ? <RotatedText $rotated={rotated}>{text}</RotatedText> : null}
          </Stack>
        </div>
      </OpenButton>
      {typeof title === 'string' ? (
        <RotatedText $rotated href={href} onClick={() => !href && onClick()}>
          {title}
        </RotatedText>
      ) : (
        title
      )}
    </MotionExpandMore>
  )
}
