import { useMemo } from 'react'

type BookingOpenArguments = {
  checkin?: string
  checkout?: string
  adults?: string
  OfferId?: string
  RoomId?: string
}

type ProfitroomBooking = {
  Open(data: BookingOpenArguments): void
  OpenVouchers(): void
}

declare const global: typeof globalThis & { Booking?: ProfitroomBooking }

type ProfitRoomResult = {
  bookingOpen: (args: BookingOpenArguments) => void
  vouchersOpen: () => void
}

export function useProfitRoom(): ProfitRoomResult {
  return useMemo(
    () => ({
      bookingOpen: (args) =>
        // eslint-disable-next-line new-cap
        global.Booking ? global.Booking.Open(args) : undefined,
      vouchersOpen: () =>
        // eslint-disable-next-line new-cap
        global.Booking ? global.Booking.OpenVouchers() : undefined,
    }),
    [],
  )
}
