import { chain } from '@obvio/utils'

import type { Client, Queries } from '@obvio/app'
import type { Dict } from '@obvio/utils'

const getEvents = {
  id: true,
  date: true,
  title: true,
  images: true,
  description: true,
  slug: true,
  startTime: true,
  category: {
    title: true,
  },
} as const

// TODO: Can we make it both typesafe and "as const"?
export const QUERIES = {
  events: { getEvents },
  'events-calendar': { getEvents },
  'events-grid': { getEvents },
  'store-categories': {
    getProductCategories: {
      slug: true,
      title: true,
      description: true,
      products: {
        title: true,
        images: true,
        slug: true,
      },
    },
  },
  rooms: {
    getProfitroomRooms: {
      id: true,
      description: true,
      title: true,
      images: true,
      profitroomId: true,
      order: true,
      category: {
        order: true,
      },
    },
  },
  peace: {
    getProfitroomRooms: {
      id: true,
      title: true,
      images: true,
      description: true,
      profitroomId: true,
      order: true,
    },
    getProfitroomOffers: {
      id: true,
      title: true,
      image: true,
      profitroomId: true,
    },
  },
  'rooms-detailed': {
    getProfitroomRoomCategories: {
      id: true,
      description: true,
      slug: true,
      title: true,
      image: true,
      order: true,
    },
  },
  packages: {
    getProfitroomOfferCategories: {
      id: true,
      title: true,
      elements: {
        id: true,
        title: true,
        numberOfNights: true,
        startingPrice: true,
        image: true,
        profitroomId: true,
      },
    },
  },
  'packages-carousel': {
    getProfitroomOffers: {
      id: true,
      description: true,
      title: true,
      image: true,
      profitroomId: true,
    },
  },
} as const

export const serverPrefetchQueries = async (
  data: { variant: string; component: string }[],
  client: Client,
): Promise<unknown[]> => {
  const queriesToFetch: [keyof Queries, Dict][] = data.reduce(
    (
      acc: any[],
      { variant, component }: { variant: string; component: string },
    ) => {
      const name =
        variant in QUERIES
          ? variant
          : component in QUERIES
          ? component
          : undefined
      if (name) {
        return [
          ...acc,
          ...Object.entries(QUERIES[name as keyof typeof QUERIES]),
        ]
      }
      return acc
    },
    [],
  )

  const promises = queriesToFetch.map(
    ([query, select]) =>
      () =>
        client.query(query, { select }),
  )

  return chain(promises)
}
