import { css, Link, mobile, styled } from '@obvio/app'
import { SvgArrowSimple } from '@obvio/svg'
import { Accordion, Drawer, Grid, Stack } from '@obvio/ui'
import { motion } from 'framer-motion'

export const NavBar = styled.header<{
  $opened: boolean
  $layoutType: string
  $black: boolean
}>`
  z-index: 51;
  position: fixed;
  top: ${(theme) => theme.spacing.big};
  left: ${(theme) => theme.spacing.big};
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: var(
    --nav-color,
    ${(theme, { $layoutType }) =>
      $layoutType === 'Kontakt' ? theme.colors.secondary : 'white'}
  );

  ${(_, { $black }) =>
    $black &&
    css`
      color: black;
    `};

  * {
    align-items: center;
    color: ${(theme, { $opened, $layoutType }) =>
      $opened || $layoutType === 'Kontakt'
        ? theme.colors.secondary
        : 'inherit'};
  }
  @media ${mobile} {
    left: 0;
    top: 1rem;
    /* background: ${(theme, { $opened }) =>
      $opened ? theme.colors.additional[0] : 'transparent'}; */
    width: auto;
  }
`

const MenuWrap = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
  left: 0;
  height: 100%;
  width: auto;
  max-width: 100vw;
  @media ${mobile} {
    flex-direction: column;
  }
`

export const MotionMenu = motion(MenuWrap)

export const Menu = styled.div`
  z-index: 2;
  min-width: 37rem;
  height: 100%;
  background: ${(theme) => theme.colors.additional[0]};
  position: relative;
  @media ${mobile} {
    width: 100vw;
    min-width: unset;
    padding-bottom: ${(theme) => theme.spacing.large};
  }
`

const ExpandMore = styled.div`
  width: 6.875rem;
  min-width: 6.875rem;
  background: ${(theme) => theme.colors.complementary.semilight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0 4rem 0;
  align-items: center;
  height: 100%;

  z-index: 1;

  > button {
    color: ${(theme) => theme.colors.secondary};
  }

  @media ${mobile} {
    flex-direction: row-reverse;
    height: 5rem;
    width: 100%;
    padding: ${(theme) => theme.spacing.large};
  }
`

export const MotionExpandMore = motion(ExpandMore)

export const EventsPanel = styled(Grid)`
  flex: 1;
  background: ${(theme) => theme.colors.complementary.semilight};
  height: 100%;
  min-width: 0;
  padding-top: 2rem;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media ${mobile} {
    padding: 2rem;
  }
`

export const GroupTitle = styled.li<{ $open?: boolean | undefined }>`
  font-size: 1rem;
  font-weight: 500;
  color: ${(theme, { $open }) => ($open ? '#8B897B' : theme.colors.secondary)};
  cursor: pointer;
  line-height: 135%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  width: fit-content;
  position: relative;
`

export const GroupElement = styled.li`
  font-size: 1.5rem;
  color: ${(theme) => theme.colors.secondary};
  font-weight: 300;
  width: fit-content;
  position: relative;
  margin-top: ${(theme) => theme.spacing.small};
`

export const AccordionNoStyles = styled(Accordion.Element)`
  all: unset;
  > div {
    all: unset;
  }
`

export const StackList = styled(Stack)`
  margin-top: 9rem;
  // align with svg logo, might go for grid later on
  margin-left: calc(2rem + 6em);
  flex: 1;
  @media ${mobile} {
    margin-left: 3.75rem;
  }
`

export const RotatedText = styled(Link)<{ $rotated: boolean }>`
  ${(_, { $rotated }) =>
    $rotated &&
    css`
      writing-mode: vertical-lr;
      transform: rotate(-180deg);
    `}
  white-space: initial;
  @media ${mobile} {
    writing-mode: initial;
    transform: none;
  }
`

export const ExpandMoreArrow = styled(SvgArrowSimple)<{
  $opened?: boolean | undefined
}>`
  transform: rotate(${(_, { $opened }) => ($opened ? '180deg' : '0deg')});
  @media ${mobile} {
    transform: rotate(${(_, { $opened }) => ($opened ? '-90deg' : '90deg')});
  }
`

export const CheckAll = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
`

export const StyledDrawer = styled(Drawer)`
  > div {
    overflow: auto;
    position: fixed;
  }
  html.no-js & {
    position: static;
    height: 30rem;
    > div {
      position: static;
      height: 100%;
      width: 100%;
      > section {
        height: 100%;
        width: 100%;
        position: static;
        > div > div > div {
          height: 100%;
          width: 100%;
          position: static;
          transform: none !important;
          > div:first-of-type {
            min-width: 100%;
          }
        }
      }
    }
  }
`
