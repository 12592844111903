import {
  css,
  Link,
  mobile,
  styled,
  useInView,
  useRouter,
  useTranslation,
} from '@obvio/app'
import { SvgAbstract, SvgMainLogo } from '@obvio/svg'
import { ObvioFooter, Stack } from '@obvio/ui'
import { useEffect, useMemo } from 'react'

import { InlineBookCalendar } from './InlineBookCalendar/InlineBookCalendar'
import { CenteredSection } from './Renderer/CenteredSection'
import { heroSectionStore } from '@/utils/heroSectionStore'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const MaxWithStack = styled(Stack)`
  width: 100%;
  h2,
  span,
  button {
    color: ${(theme) => theme.colors.secondary};
  }
`

const MaxWidthCalendar = styled(InlineBookCalendar)`
  max-width: unset;
  color: ${(theme) => theme.colors.secondary};
`

const ReserveNow = styled.span`
  color: ${(theme) => theme.colors.secondary};
`

const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  @media ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  > * {
    margin-bottom: 2rem;
  }
`

const LinksWrap = styled(InfoWrap)`
  font-size: 0.75rem;
`

const Info = styled.span`
  font-weight: 300;
`

const CalendarSection = styled(CenteredSection)`
  * {
    color: ${(theme) => theme.colors.secondary};
  }
  @media ${mobile} {
    max-width: 100vw;
    padding: 0;
    border-bottom: 1px solid ${(theme) => theme.stroke};
  }
`

function LinksBar(): ReactElement {
  const { t } = useTranslation()

  return (
    <LinksWrap>
      <InfoCell top="©2021 Poziom 511" bottom={t('footer.allRightsReserved')} />
      <InfoCell
        bottom={<Link href="/informacje">{t('footer.importantInfo')}</Link>}
      />
      <InfoCell
        bottom={<Link href="/regulamin">{t('footer.hotelTerms')}</Link>}
      />
      <InfoCell
        bottom={<Link href="/regulamin-sklepu">{t('footer.storeTerms')}</Link>}
      />
      <InfoCell
        bottom={
          <Link href="/polityka-prywatnosci">{t('footer.privacyPolicy')}</Link>
        }
      />
      <InfoCell bottom={<Link href="/covid">{t('footer.covidRules')}</Link>} />
    </LinksWrap>
  )
}

type InfoCellProps = AllowUndefinedForOptional<{
  top?: string | ReactElement
  bottom?: string | ReactElement
}>

function InfoCell({ top, bottom }: InfoCellProps) {
  return (
    <Stack kind="vertical" spacing="extraSmall">
      {top ? <Info>{top}</Info> : null}
      {bottom ? <Info>{bottom}</Info> : null}
    </Stack>
  )
}

function InfoBar(): ReactElement {
  return (
    <InfoWrap>
      <SvgAbstract />
      <InfoCell top="ul. Bonerów 33" bottom="42-440 Podzamcze k. Ogrodzieńca" />
      <InfoCell top="32 746 28 00" bottom="recepcja@poziom511.com" />
      <InfoCell top="Facebook" bottom="Instagram" />
    </InfoWrap>
  )
}

const PoziomFooter = styled(ObvioFooter)`
  border-top: 1px solid ${(theme) => theme.stroke};
  background: ${(theme) => theme.background};
`

type FooterProps = AllowUndefinedForOptional<{
  store?: boolean
}>

export function Footer({ store }: FooterProps): ReactElement {
  const { pathname } = useRouter()
  const inViewOptions = useMemo(() => ({ threshold: 0.5 }), [])
  const { ref, inView } = useInView(inViewOptions)
  const [, dispatch] = heroSectionStore('calendar')

  useEffect(
    () => dispatch('SET_CALENDAR_VISIBILITY', !inView),
    [inView, dispatch],
  )

  const sectionOuterStyle = useMemo(
    () => css`
      border-top: ${(theme) => (store ? `none` : `1px solid ${theme.stroke}`)};
    `,
    [store],
  )

  const isLanding = pathname === '/'

  return (
    <>
      <CenteredSection withMargin={!isLanding} outerCss={sectionOuterStyle}>
        <ReserveNow>
          <SvgMainLogo />
        </ReserveNow>
      </CenteredSection>
      <CalendarSection>
        <MaxWidthCalendar footer ref={(v) => ref(v as HTMLElement)} />
      </CalendarSection>
      <CenteredSection>
        <MaxWithStack kind="vertical" spacing="extraLarge">
          <InfoBar />
          <LinksBar />
        </MaxWithStack>
      </CenteredSection>
      <PoziomFooter />
    </>
  )
}
