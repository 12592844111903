import { Context, css, Link, notDesktop, styled } from '@obvio/app'
import { StoreNav as StoreNavTemplate, useCategories } from '@obvio/template'
import { useEffect, useMemo, useState } from 'react'

import { Nav } from '../Nav/Nav'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const MenuLink = styled(Link)`
  ${(theme) => theme.flexCenter};
  justify-content: flex-start;
  > span {
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.3em;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
  }
  @media ${notDesktop} {
    display: none;
  }
`

const StyledStoreNavTemplate = styled(StoreNavTemplate)<{
  $transparent: boolean
}>`
  position: fixed !important;
  border: none;
  > div:last-of-type {
    span {
      color: black !important;
    }
  }

  background: ${(theme) => theme.background};
  border-bottom: 1px solid ${(theme) => theme.stroke};
  ${(_, { $transparent }) =>
    $transparent &&
    css`
      position: absolute;
      background: transparent;
      border-bottom: none;
      * {
        color: white !important;
      }
    `}
`

type StoreNavProps = AllowUndefinedForOptional<{
  transparent?: boolean
}>

export function StoreNav({ transparent }: StoreNavProps): ReactElement {
  const [top, setTop] = useState(true)
  const { categories } = useCategories()

  const storeNavLinks = useMemo(
    () =>
      categories?.map((link) => ({
        id: link.id,
        href: `/sklep/kategoria/${link.slug}`,
        label: link.title,
      })),
    [categories],
  )

  useEffect(() => {
    function set() {
      setTop(window.scrollY <= 0)
    }
    window.addEventListener('scroll', set)

    return () => window.removeEventListener('scroll', set)
  }, [])

  return (
    <>
      <Context name="default" render="div">
        <Nav black={!top || !transparent} noAbstract />
      </Context>
      <Context name="ecommerce" render="div">
        <StyledStoreNavTemplate
          $transparent={top && Boolean(transparent)}
          logo={() => <div />}
          storeNavLinks={storeNavLinks ?? []}
          menuLink={MenuLink}
          noSearch
        />
      </Context>
    </>
  )
}
