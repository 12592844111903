import { createStore } from '@obvio/app'

export const heroSectionStore = createStore<
  { hero: boolean; calendar: boolean },
  {
    SET_HERO_VISIBILITY: boolean
    SET_CALENDAR_VISIBILITY: boolean
  }
>(
  {
    SET_HERO_VISIBILITY: (_state, visibility: boolean) => {
      const root = document.documentElement
      root.style.setProperty('--nav-color', visibility ? 'white' : '#31352C')

      return { hero: visibility, calendar: !visibility }
    },
    SET_CALENDAR_VISIBILITY: (_state, visibility) => ({
      calendar: visibility,
    }),
  },
  { hero: true, calendar: false },
)
